import SlideshowFoundation from './foundation';
import { isNumeric } from './util';

class SlideShow {
  constructor(root, foundation = undefined, ...args) {
    /** @protected {!Element} */
    this.root_ = root;

    /** @protected {!Element} */
    this.container_;

    /** @protected {!Element} */
    this.inner_;

    /** @protected {!DOMList} */
    this.directions_

    this.indicator_

    this.directionHandler_ = evt => this.onDirection(evt);

    this.initialize(...args);


    // Note that we initialize foundation here and not within the constructor's default param so that
    // this.root_ is defined and can be used within the foundation class.
    /** @protected {!F} */
    this.foundation_ = foundation === undefined ? this.getDefaultFoundation() : foundation;
    this.foundation_.init();
    this.initialSyncWithDOM();
  }

  initialize() {
    // TODO: Add class as constant with strings eg. strings.CONTAINER_SELECTOR
    this.container_ = this.root_.querySelector('.Slideshow__container');
    this.inner_ = this.root_.querySelector('.Slideshow__inner');
    this.directions_ = this.container_.querySelectorAll('.Slideshow__dir');
    this.indicator_ = this.root_.querySelector('.Slideshow__indicator__current');
  }

  /** @return {number} */
  get length() {
    return this.foundation_.getLength();
  }

  /** @param {number} max */
  set length(length) {
    this.foundation_.setLength(length);
  }

  /** @return {number} */
  get current() {
    return this.foundation_.getCurrent();
  }

  /** @param {number} max */
  set current(n) {
    this.foundation_.setCurrent(n);
  }

  /**
   * @return {!MDCSliderFoundation}
   */
  getDefaultFoundation() {
    return new SlideshowFoundation(
      ({
        hasClass: (className) => this.root_.classList.contains(className),
        addClass: (className) => this.root_.classList.add(className),
        removeClass: (className) => this.root_.classList.remove(className),
        getAttribute: (name) => this.root_.getAttribute(name),
        setAttribute: (name, value) => this.root_.setAttribute(name, value),
        removeAttribute: (name) => this.root_.removeAttribute(name),
        computeBoundingRect: () => this.container_.getBoundingClientRect(),
        getTabIndex: () => this.root_.tabIndex,

        // For events like keydown, focus, blur
        registerInteractionHandler: (type, handler) => {
          this.root_.addEventListener(type, handler);
        },
        deregisterInteractionHandler: (type, handler) => {
          this.root_.removeEventListener(type, handler);
        },

        // For events drag events slideshow
        registerContainerInteractionHandler: (type, handler) => {
          this.container_.addEventListener(type, handler);
        },
        deregisterContainerInteractionHandler: (type, handler) => {
          this.container_.removeEventListener(type, handler);
        },

        // Body events
        registerBodyInteractionHandler: (type, handler) => {
          document.body.addEventListener(type, handler);
        },
        deregisterBodyInteractionHandler: (type, handler) => {
          document.body.removeEventListener(type, handler);
        },

        // Resize event
        registerResizeHandler: (handler) => {
          window.addEventListener('resize', handler);
        },
        deregisterResizeHandler: (handler) => {
          window.removeEventListener('resize', handler);
        },

        // Emit
        notifyChange: () => {
          this.updateCurrent(this.current);
          this.emit('SlideShow:change', this.current);
        },

        setContainerStyleProperty: (propertyName, value) => {
          this.inner_.style.setProperty(propertyName, value);
        }
      })
    );
  }

  initialSyncWithDOM() {
    // Set slidehsow length
    this.length = this.inner_.querySelectorAll('.Slideshow__item').length || this.length;

    // Setup direction controls
    this.directions_.forEach( dir => {
      dir.addEventListener('click', this.directionHandler_);
    });

    // const origValueNow = parseFloat(this.root_.getAttribute(strings.ARIA_VALUENOW));
    // this.max = parseFloat(this.root_.getAttribute(strings.ARIA_VALUEMAX)) || this.max;
    // this.min = parseFloat(this.root_.getAttribute(strings.ARIA_VALUEMIN)) || this.min;
    // this.step = parseFloat(this.root_.getAttribute(strings.STEP_DATA_ATTR)) || this.step;
    // this.value = origValueNow || this.value;
    // this.disabled = (
    //   this.root_.hasAttribute(strings.ARIA_DISABLED) &&
    //   this.root_.getAttribute(strings.ARIA_DISABLED) !== 'false'
    // );
    // this.foundation_.setupTrackMarker();
  }

  destroy() {
    // Subclasses may implement this method to release any resources / deregister any listeners they have
    // attached. An example of this might be deregistering a resize event from the window object.
    this.foundation_.destroy();
  }

  onDirection(evt) {
    evt.preventDefault();
    const step = evt.currentTarget.getAttribute('data-step');

    if ( isNumeric(step) ) {
      this.foundation_.setCurrent(this.foundation_.getCurrent() + parseInt(step));
    }
  }

  updateCurrent(number) {
    if (this.indicator_) {
      this.indicator_.innerText = number + 1;
    }
  }

  /**
   * Wrapper method to add an event listener to the component's root element. This is most useful when
   * listening for custom events.
   * @param {string} evtType
   * @param {!Function} handler
   */
  listen(evtType, handler) {
    this.root_.addEventListener(evtType, handler);
  }

  /**
   * Wrapper method to remove an event listener to the component's root element. This is most useful when
   * unlistening for custom events.
   * @param {string} evtType
   * @param {!Function} handler
   */
  unlisten(evtType, handler) {
    this.root_.removeEventListener(evtType, handler);
  }

  /**
   * Fires a cross-browser-compatible custom event from the component root of the given type,
   * with the given data.
   * @param {string} evtType
   * @param {!Object} evtData
   * @param {boolean=} shouldBubble
   */
  emit(evtType, evtData, shouldBubble = false) {
    let evt;
    if (typeof CustomEvent === 'function') {
      evt = new CustomEvent(evtType, {
        detail: evtData,
        bubbles: shouldBubble,
      });
    } else {
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    this.root_.dispatchEvent(evt);
  }
};

export { SlideShow, SlideshowFoundation };
