import { getCorrectPropertyName } from './util';

/** @enum {string} */
const KEY_IDS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  HOME: 'Home',
  END: 'End',
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
};

/** @enum {string} */
const cssClasses = {
  ACTIVE: 'Slideshow--active',
  DISABLED: 'Slideshow--disabled',
  FOCUS: 'Slideshow--focus',
  IS_DRAGGING: 'Slideshow--is-dragging',
};

/** @enum {string} */
const MOVE_EVENT_MAP = {
  // 'mousedown': 'mousemove',
  'touchstart': 'touchmove',
  // 'pointerdown': 'pointermove',
};

const DOWN_EVENTS = [
  // 'mousedown',
  // 'pointerdown',
  'touchstart'
];
const UP_EVENTS = ['mouseup', 'pointerup', 'touchend'];

const transformProp = getCorrectPropertyName(window, 'transform');

class SlideshowFoundation {
  /** @return enum {cssClasses} */
  static get cssClasses() {
    return cssClasses;
  }

  /** @return enum {strings} */
  static get strings() {
    return strings;
  }

  /** @return enum {numbers} */
  static get numbers() {
    return numbers;
  }

  /** @return {!MDCSliderAdapter} */
  static get defaultAdapter() {
    return /** @type {!MDCSliderAdapter} */ ({
      hasClass: (/* className: string */) => /* boolean */ false,
      addClass: (/* className: string */) => {},
      removeClass: (/* className: string */) => {},
      getAttribute: (/* name: string */) => /* string|null */ null,
      setAttribute: (/* name: string, value: string */) => {},
      removeAttribute: (/* name: string */) => {},
      computeBoundingRect: () => /* ClientRect */ ({
        top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0,
      }),
      getTabIndex: () => /* number */ 0,

      // For events like keydown, focus, blur
      registerInteractionHandler: (/* type: string, handler: EventListener */) => {},
      deregisterInteractionHandler: (/* type: string, handler: EventListener */) => {},

      // For events drag events slideshow
      registerContainerInteractionHandler: (/* type: string, handler: EventListener */) => {},
      registerContainerInteractionHandler: (/* type: string, handler: EventListener */) => {},

      // Body events
      registerBodyInteractionHandler: (/* type: string, handler: EventListener */) => {},
      deregisterBodyInteractionHandler: (/* type: string, handler: EventListener */) => {},

      // Resize event
      registerResizeHandler: (/* handler: EventListener */) => {},
      deregisterResizeHandler: (/* handler: EventListener */) => {},

      // Emit
      notifyChange: () => {},

      setContainerStyleProperty: (/* propertyName: string, value: string */) => {}
    });
  }

  /**
   * Creates a new instance of MDCSliderFoundation
   * @param {?MDCSliderAdapter} adapter
   */
  constructor(adapter = {}) {
    /** @protected {!A} */
    this.adapter_ = Object.assign(SlideshowFoundation.defaultAdapter, adapter);

    // Inner container event handler
    this.interactionStartHandler_ = (evt) => this.handleDown_(evt);

    this.preventFocusState_ = false;
    this.rect_ = null;
    this.currentN_ = 0;
    this.length_ = 0;

    // Drag properties
    this.startX_ = 0;
    this.currentX_ = 0;
    this.lastX_ = 0;
    this.isDragging_ = false;

    // this.savedTabIndex_ = NaN;
    // this.active_ = false;

    //  Slideshow event handlers
    this.keydownHandler_ = (evt) => this.handleKeydown_(evt);
    this.focusHandler_ = () => this.handleFocus_();
    this.blurHandler_ = () => this.handleBlur_();

    // Window event handlers
    this.resizeHandler_ = () => this.layout();
  }

  init() {
    // Registrer touchstart events
    DOWN_EVENTS.forEach((evtName) => this.adapter_.registerContainerInteractionHandler(evtName, this.interactionStartHandler_));

    // Set current slide
    this.layout();

    this.adapter_.registerInteractionHandler('keydown', this.keydownHandler_);
    this.adapter_.registerInteractionHandler('focus', this.focusHandler_);
    this.adapter_.registerInteractionHandler('blur', this.blurHandler_);

    // On resize
    this.adapter_.registerResizeHandler(this.resizeHandler_);
  }

  destroy() {
    // Destory object
  }

  layout() {
    this.rect_ = this.adapter_.computeBoundingRect();
    this.setPosition_();
  }

  /**
   * Called when the user starts interacting with the slider
   * @param {!Event} evt
   * @private
   */
  handleDown_(evt) {
    evt.preventDefault();

    this.preventFocusState_ = true;
    this.setActive_(true);

    const moveHandler = (evt) => {
      this.handleMove_(evt);
    };

    // Note: upHandler is [de]registered on ALL potential pointer-related release event types, since some browsers
    // do not always fire these consistently in pairs.
    // (See https://github.com/material-components/material-components-web/issues/1192)
    const upHandler = () => {
      this.handleUp_();

      // Remove "Session" events (self deregistrer)
      this.adapter_.deregisterBodyInteractionHandler(MOVE_EVENT_MAP[evt.type], moveHandler);
      UP_EVENTS.forEach((evtName) => this.adapter_.deregisterBodyInteractionHandler(evtName, upHandler));
    };

    // Registrer "Session events"
    this.adapter_.registerBodyInteractionHandler(MOVE_EVENT_MAP[evt.type], moveHandler);
    UP_EVENTS.forEach((evtName) => this.adapter_.registerBodyInteractionHandler(evtName, upHandler));

    // Save startX and set current
    this.startX_ = this.getPageX_(evt);
    this.currentX_ = this.startX_;
    this.startX_ -= this.lastX_;
    this.setIsDragging_(true);
  }

  setPosition_ () {
    this.lastX_ = (this.currentN_ * this.rect_.width) * -1;
    this.adapter_.setContainerStyleProperty(transformProp, `translate3d(${this.lastX_}px, 0, 0)`);
    this.adapter_.notifyChange();
  }

  /**
   * Called when the user moves the slider
   * @param {!Event} evt
   * @private
   */
  handleMove_(evt) {
    evt.preventDefault();

    if (!this.isDragging_) {
      return;
    }

    this.currentX_ = this.getPageX_(evt);

    const x = this.currentX_ - this.startX_;
    this.adapter_.setContainerStyleProperty(transformProp, `translate3d(${x}px, 0, 0)`);
  }

  /**
   * Called when the user's interaction with the slider ends
   * @private
   */
  handleUp_() {
    this.setIsDragging_(false);
    // (new position) - prev postion
    const distanceMoved = (this.currentX_ - this.startX_) - this.lastX_;
    this.lastX_ = this.currentX_ - this.startX_;

    // If moved more than X and
    if (
        (distanceMoved < -20 && this.currentN_ < (this.length_ - 1))
      ||
        (distanceMoved > 20 && this.currentN_ > 0))
    {
      this.currentN_ += (distanceMoved < 0 ? 1 : -1);
      this.setPosition_();
    } else {
      this.setPosition_();
    }
  }

  handleFocus_() {
    if (this.preventFocusState_) {
      return;
    }

    this.adapter_.addClass(cssClasses.FOCUS);
  }

  handleBlur_() {
    this.preventFocusState_ = false;
    this.adapter_.removeClass(cssClasses.FOCUS);
  }

  /**
   * Handles keydown events
   * @param {!Event} evt
   */
  handleKeydown_(evt) {
    const keyId = this.getKeyId_(evt);
    const value = this.getValueForKeyId_(keyId);
    if (isNaN(value)) {
      return;
    }

    // Prevent page from scrolling due to key presses that would normally scroll the page
    evt.preventDefault();

    // this.adapter_.addClass(cssClasses.FOCUS);
    this.currentN_ = value;
    this.setPosition_();
  }

  /**
   * Returns the computed name of the event
   * @param {!Event} kbdEvt
   * @return {string}
   */
  getKeyId_(kbdEvt) {
    if (kbdEvt.key === KEY_IDS.ARROW_LEFT || kbdEvt.keyCode === 37) {
      return KEY_IDS.ARROW_LEFT;
    }
    if (kbdEvt.key === KEY_IDS.ARROW_RIGHT || kbdEvt.keyCode === 39) {
      return KEY_IDS.ARROW_RIGHT;
    }
    if (kbdEvt.key === KEY_IDS.ARROW_UP || kbdEvt.keyCode === 38) {
      return KEY_IDS.ARROW_UP;
    }
    if (kbdEvt.key === KEY_IDS.ARROW_DOWN || kbdEvt.keyCode === 40) {
      return KEY_IDS.ARROW_DOWN;
    }
    if (kbdEvt.key === KEY_IDS.HOME || kbdEvt.keyCode === 36) {
      return KEY_IDS.HOME;
    }
    if (kbdEvt.key === KEY_IDS.END || kbdEvt.keyCode === 35) {
      return KEY_IDS.END;
    }
    if (kbdEvt.key === KEY_IDS.PAGE_UP || kbdEvt.keyCode === 33) {
      return KEY_IDS.PAGE_UP;
    }
    if (kbdEvt.key === KEY_IDS.PAGE_DOWN || kbdEvt.keyCode === 34) {
      return KEY_IDS.PAGE_DOWN;
    }

    return '';
  }

  /**
   * Computes the value given a keyboard key ID
   * @param {string} keyId
   * @return {number}
   */
  getValueForKeyId_(keyId) {
    switch (keyId) {
    case KEY_IDS.ARROW_LEFT:
    case KEY_IDS.ARROW_DOWN:
    case KEY_IDS.PAGE_DOWN:
      // Go left
      return this.currentN_ > 0 ? this.currentN_ - 1 : NaN;
    case KEY_IDS.ARROW_RIGHT:
    case KEY_IDS.ARROW_UP:
    case KEY_IDS.PAGE_UP:
      // Go right
      return this.currentN_ < (this.length_ - 1) ? this.currentN_  + 1 : NaN;
    case KEY_IDS.HOME:
      // Go to first image
      return 0;
    case KEY_IDS.END:
      // Go to last image
      return (this.length_ - 1);
    default:
      return NaN;
    }
  }

  /**
   * Returns the pageX of the event
   * @param {!Event} evt
   * @return {number}
   * @private
   */
  getPageX_(evt) {
    if (evt.targetTouches && evt.targetTouches.length > 0) {
      return evt.targetTouches[0].pageX;
    }
    return evt.pageX;
  }

  /**
   * Toggles the active state of the slider
   * @param {boolean} active
   */
  setActive_(active) {
    this.active_ = active;
    this.toggleClass_(cssClasses.ACTIVE, this.active_);
  }

  /**
   * Toggles the inTransit state of the slider
   * @param {boolean} inTransit
   */
  setIsDragging_(isDragging) {
    this.isDragging_ = isDragging;
    this.toggleClass_(cssClasses.IS_DRAGGING, this.isDragging_);
  }

  /**
   * Conditionally adds or removes a class based on shouldBePresent
   * @param {string} className
   * @param {boolean} shouldBePresent
   */
  toggleClass_(className, shouldBePresent) {
    if (shouldBePresent) {
      this.adapter_.addClass(className);
    } else {
      this.adapter_.removeClass(className);
    }
  }

  /** @return {number} */
  getLength() {
    return this.length_;
  }

  /** @param {number} max */
  setLength(length) {
    this.length_ = length;
  }

  /** @return {number} */
  getCurrent() {
    return this.currentN_;
  }

  /** @param {number} max */
  setCurrent(number) {
    if (number > (this.length_ - 1) || number < 0) {
      return;
    }

    // Update current
    this.currentN_ = number;

    // Set new position
    this.setPosition_();
  }
}

export default SlideshowFoundation;
