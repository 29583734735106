// imports
import styles from '../css/app.scss';
import noScroll from 'no-scroll';
import throttle from 'lodash.throttle';
import svg4everybody from 'svg4everybody';
import IntersectionObserverPoly from 'intersection-observer';
import axios from 'axios';

//import Forms from './components/Forms';
import { SlideShow }  from './components/SlideShow'

//
// App async loading modules
//
const main = async () => {

  // Load vimeo API if vimeo video exist
  const video = document.getElementById('HeroVimeoBG');

  if (video) {
    const vimeo = await import(/* webpackChunkName: "Player" */ '@vimeo/player');
    const VimeoPlayer = vimeo.default;
    const videoControls = document.getElementById('HeroVimeoControls');
    const playbtn = document.getElementById('PlayBtn');
    const wrapper = document.getElementById('Hero');
    const closebtn = document.getElementById('HeroClose');

    const VideoModalBtn = document.getElementById('VideoModalBtn');
    const VideoModalCloseBtn = document.getElementById('VideoModalClose');
    const VideoModalBackdrop = document.getElementById('VideoModalBgClose');
    
    let playerBG = new VimeoPlayer(video, {
      // options set in HTML data attributes and additional options here are (apparently) ignored
    });
    let playerControls = new VimeoPlayer(videoControls, {
      // options set in HTML data attributes and additional options here are (apparently) ignored
    });

    playbtn.addEventListener('click', () => {
      playerBG.pause();
      playerControls.play();
      wrapper.classList.add('Hero--play');
      videoControls.style.display = 'block';
    });

    closebtn.addEventListener('click', () => {
      playerControls.pause();
      playerBG.play();
      wrapper.classList.remove('Hero--play');
      videoControls.style.display = 'none';
    });

    VideoModalBtn.addEventListener('click', () => { noScroll.on(); });
    VideoModalCloseBtn.addEventListener('click', () => { noScroll.off(); });
    VideoModalBackdrop.addEventListener('click', () => { noScroll.off(); });
  }
};

//
// Application
//
const Vestbris = {

  // Options / globals
  lastScrolltop: 0,
  headerIsHidden: false,
  headerElement: null,
  burgerMenubtn: null,
  navOverlay: null,
  loadMoreBtn: null,
  hero: null,

  init () {
    // Execute async function
    main().then( () => {
      console.log('Modules loaded');
    });

    // header element
    this.headerElement = document.getElementById('Header');

    // Burger menu noscroll toggle
    this.burgerMenubtn = document.getElementById('NavTrigger');
    if (this.burgerMenubtn) {
      this.burgerMenubtn.addEventListener('click', this.toggleNoScroll);
    }
    // Nav overlay toggle no scroll
    this.navOverlay = document.getElementById('NavOverlay');
    if (this.navOverlay) {
      this.navOverlay.addEventListener('click', this.toggleNoScroll);
    }

    // Hero
    this.hero = document.getElementById('Hero');
    if (this.hero) {
      window.addEventListener('scroll', throttle(this.onScrollHero, 1000 / 60));
    }

    // Init swipe in images on scroll
    if ( document.querySelector('.WillSwipe') ) {
      this.observeImages();
    }

    const slideshow = document.querySelectorAll('.Slideshow');

    if (slideshow.length > 0) {
      slideshow.forEach(slide => {
        new SlideShow(slide);
      });
    }

    // Load more posts button
    this.loadMoreBtn = document.getElementById('LoadMoreBtn');
    if (this.loadMoreBtn) {
      console.log('load more present');
      this.loadMoreBtn.addEventListener('click', (e) => {
        this.loadMorePosts(e);
      });
    }

    // On scroll
    window.addEventListener('scroll', throttle(this.onScroll, 1000 / 60));
    window.addEventListener('scroll', throttle(this.scrollDelayed, 200));

    // Forms
    //Forms.init();

    // Polyfills
    svg4everybody();
  },

  initOnLoad () {

  },

  onResize () {
    console.log('on resize');
  },

  onScroll () {
    const vp = window.pageYOffset || document.documentElement.scrollTop;

    if (!Vestbris.headerIsHidden && vp > 5 && vp > Vestbris.lastScrolltop + 30) {
      // Hide header on scroll down
      Vestbris.headerElement.classList.add('Header--hidden');
      Vestbris.headerIsHidden = true;
    } else if (Vestbris.headerIsHidden && vp < Vestbris.lastScrolltop - 30) {
      // Show header on scroll up
      Vestbris.headerElement.classList.remove('Header--hidden');
      Vestbris.headerIsHidden = false;
    }
  },

  onScrollHero () {
    const vp = window.pageYOffset || document.documentElement.scrollTop;
    if (window.innerWidth > 1023) {
      // Check if hero in viewport
      if (vp <= Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
        // Calc viewport percentage scrolled.
        const percScrolled = (vp / Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) * 100;
        Vestbris.hero.style.visibility = 'visible';
        Vestbris.hero.style.transform = 'translate(0%, ' + ((percScrolled / 2) * -1) + '%) translate3d(0px, 0px, 0px)';
      } else {
        Vestbris.hero.style.visibility = 'hidden';
      }
    }
  },

  scrollDelayed () {
    Vestbris.lastScrolltop = window.pageYOffset || document.documentElement.scrollTop;
  },

  toggleNoScroll () {
    noScroll.toggle();
  },

  observeImages () {

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4
    }
    let observer = null;

    if (typeof IntersectionObserver !== "undefined") {
      // Observer API exists
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            entry.target.classList.add('SwipeIn');
            observer.unobserve( entry.target );
          }
        });
      }, options);
    } else {
      // Init observer with polyfill
      observer = new IntersectionObserverPoly((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            entry.target.classList.add('SwipeIn');
            observer.unobserve( entry.target );
          }
        });
      }, options);
    }

    const images = Array.from( document.querySelectorAll('.WillSwipe') );
    images.forEach(image => {
      observer.observe(image);
    });

  },

  loadMorePosts(e) {
    let btnText = this.loadMoreBtn.innerText;
    this.loadMoreBtn.innerText = 'Laster...';
    this.loadMoreBtn.style.opacity = 0.6;
    this.loadMoreBtn.style.pointerEvents = 'none';
    let postType = e.srcElement.getAttribute('data-type');
    let contentEl = document.getElementById('Content');
    let filterIds = [];

    if (postType === 'post') {
      let news = Array.from( document.querySelectorAll('.News') );
      news.forEach(newsArticle => {
        filterIds.push(parseInt(newsArticle.getAttribute('data-id')));
      });
    }
    if (postType === 'reference') {
      let references = Array.from( document.querySelectorAll('.References__single') );
      references.forEach(reference => {
        filterIds.push(parseInt(reference.getAttribute('data-id')));
      });
    }
    
    // console.log('post type to get: ', postType);
    // console.log('exclude existing posts: ', filterIds);

    axios.post(THEME.restURL + 'vestbris/v1/load-posts', { post_type: postType, filter_ids: filterIds }, { headers: {
      'X-WP-Nonce': THEME.nonce,
    } })  
    .then( (response) => {
      // Success
      let node = document.createElement("DIV");
      node.innerHTML = response.data.content;
      node.style.transition = 'opacity 1500ms';
      node.style.opacity = '0';
      contentEl.appendChild(node);
      setTimeout( () => {
        node.style.opacity = '1';
      }, 10);
      // console.log(response.data.moreposts);
      if (response.data.moreposts) {
        this.loadMoreBtn.innerText = btnText;
        this.loadMoreBtn.style.opacity = 1;
        this.loadMoreBtn.style.pointerEvents = 'auto';
      } else {
        // No more posts to be retrieved
        this.loadMoreBtn.blur();
        this.loadMoreBtn.style.opacity = 0.5;
        this.loadMoreBtn.style.cursor = 'not-allowed';
        this.loadMoreBtn.classList.remove('Button--outline');
        this.loadMoreBtn.style.pointerEvents = 'none';
        if (postType === 'post') {
          this.loadMoreBtn.innerText = 'Alle nyheter hentet'; 
        }
        if (postType === 'reference') {
          this.loadMoreBtn.innerText = 'Alle referanser hentet'; 
        }
      }
    })
    .catch( (error) => {
      // Error
      this.loadMoreBtn.innerText = btnText;
      this.loadMoreBtn.style.pointerEvents = 'auto';
      console.log(error);
    });
  },

};

document.addEventListener('DOMContentLoaded', () => Vestbris.init());
window.addEventListener('load', () => Vestbris.initOnLoad());
